import { SideNav } from '@models/SideNav';

export const SIDENAV: SideNav[] = [
  {
    label: 'Super Dashboard',
    path: `/SuperDashboard`,
    icon: {
      fontIcon: 'fa-chart-simple',
      fontSet: 'fa-solid',
      className: 'icon',
    },
    allowedRole: ['SuperOwner'],
    id: 'superDashboard',
  },
  {
    path: '/dashboard',
    label: 'Dashboard',
    icon: {
      className: 'icon',
      svgIcon: 'Dashboard',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Supervisor',
      'Manager',
      'Service',
      'Marketing',
      'Support',
    ],

    id: 'dashboard',
  },
  {
    label: 'User',
    path: `/users`,
    icon: {
      fontIcon: 'fa-user',
      fontSet: 'fa-regular',
      className: 'icon',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Support',
      'Supervisor',
      'Service',
      'Manager',
      'Marketing',
    ],
    id: 'user',
  },
  {
    label: 'Organization',
    path: `/organizations`,
    icon: {
      className: 'icon',
      fontIcon: 'fa-building',
      fontSet: 'fa-regular',
    },
    allowedRole: ['SuperOwner'],
    id: 'organization',
  },
  {
    label: 'Lead',
    path: `/leads`,
    icon: {
      className: 'icon',
      fontIcon: 'fa-user',
      fontSet: 'fa-solid',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Manager',
      'Marketing',
      'Service',
      'Supervisor',
      'Support',
    ],
    id: 'lead',
  },
  {
    label: 'Visitors',
    path: `/visitors`,
    icon: {
      className: 'icon',
      fontIcon: 'fa-compass',
      fontSet: 'fa-regular',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Support',
      'Supervisor',
      'Manager',
      'Marketing',
      'Service',
    ],

    id: 'visitor',
  },
  {
    label: 'Query',
    path: `/query`,
    icon: {
      className: 'icon',
      fontIcon: 'fa-question',
      fontSet: 'fa-solid',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Manager',
      'Support',
      'Supervisor',
      'Marketing',
      'Service',
    ],
    id: 'query',
  },
  {
    label: 'Feedback',
    path: `/feedbacks`,
    icon: {
      className: 'icon',
      svgIcon: 'query',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Manager',
      'Support',
      'Service',
      'Supervisor',
      'Marketing',
    ],
    id: 'feedback',
  },
  {
    label: 'Appointment',
    path: `/appointments`,
    icon: {
      className: 'icon -mt-1',
      fontSet: 'fa-solid',
      fontIcon: 'fa-calendar-check',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Manager',
      'Support',
      'Marketing',
      'Service',
      'Supervisor',
    ],
    id: 'appointment',
  },
  {
    label: 'Setting',
    path: `/setting`,
    icon: {
      fontIcon: 'fa-gear',
      fontSet: 'fa-solid',
      className: 'text-[1rem] ',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Manager',
      'Marketing',
      'Service',
      'Supervisor',
      'Support',
    ],
    id: 'setting',
  },

  {
    label: 'Offer & Event',
    path: `/offers`,
    icon: {
      className: 'icon',
      svgIcon: 'gift',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Marketing',
      'Supervisor',
      'Support',
      'Service',
      'Manager',
    ],
    id: 'offer',
  },
  {
    label: 'Department',
    icon: {
      className: 'icon',
      fontIcon: 'fa-code-branch',
      fontSet: 'fa-solid',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Manager',
      'Service',
      'Support',
      'Marketing',
      'Supervisor',
    ],
    path: '/departments',
    id: 'department',
  },
  {
    label: 'Agent Analytics',
    icon: {
      className: 'icon',
      fontIcon: 'fa-user-tie',
      fontSet: 'fa-solid',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Support',
      'Service',
      'Marketing',
      'Manager',
      'Supervisor',
    ],
    path: '/agents/analytics',
    id: 'agent',
  },
  {
    label: 'System Loggers',
    icon: {
      className: 'icon -mt-1',
      iconName: 'receipt_long',
    },
    allowedRole: ['SuperOwner', 'Admin'],
    path: '/systemLoggers',
    id: 'logger',
  },
  {
    label: 'Complaint',
    icon: {
      className: 'icon -mt-1',
      fontIcon: 'fa-triangle-exclamation',
      fontSet: 'fa-solid',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Support',
      'Service',
      'Manager',
      'Supervisor',
      'Marketing',
    ],
    path: '/complaints',
    id: 'complaint',
  },
  {
    label: 'Campaign',
    path: `/campaigns`,
    icon: {
      className: 'icon',
      iconName: 'campaign',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Marketing',
      'Manager',
      'Service',
      'Supervisor',
      'Support',
    ],
    id: 'campaign',
  },
  {
    label: 'Api Logs',
    path: `/apiLogs`,
    icon: {
      className: 'icon',
      fontIcon: 'fa-clipboard-list',
      fontSet: 'fa-solid',
    },
    allowedRole: ['SuperOwner', 'Admin'],
    id: 'apiLog',
  },
  {
    label: ' Test Drive',
    path: `/testdrive`,
    icon: {
      className: 'icon',
      fontIcon: 'fa-car-side',
      fontSet: 'fa-solid',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Manager',
      'Marketing',
      'Supervisor',
      'Service',
      'Support',
    ],
    id: 'testDrive',
  },
  {
    label: 'Query Feedback',
    path: `/likeDislikeQuery`,
    icon: {
      className: 'icon',
      fontIcon: 'fa-envelope-open-text',
      fontSet: 'fa-solid',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Manager',
      'Support',
      'Supervisor',
      'Service',
      'Marketing',
    ],
    id: 'likeDislikeQuery',
  },
  {
    label: ' Group',
    path: `/group`,
    icon: {
      className: 'icon',
      fontIcon: ' fa-people-group',
      fontSet: 'fa-solid',
    },
    allowedRole: [
      'SuperOwner',
      'Admin',
      'Manager',
      'Support',
      'Supervisor',
      'Service',
      'Marketing',
    ],
    id: 'group',
  },

  {
    label: 'Chats',
    icon: {
      className: 'icon',
      fontIcon: 'fa-comments',
      fontSet: 'fa-solid',
    },
    id: 'chatHistory',
    allowedRole: [
      'Service',
      'SuperOwner',
      'Admin',
      'Manager',
      'Supervisor',
      'Support',
      'Marketing',
    ],
    children: [
      {
        label: 'Today',
        path: '/chats/',
        params: {
          duration: 'today',
        },
      },
      {
        label: 'Yesterday',
        path: '/chats',
        params: {
          duration: 'yesterday',
        },
      },
      {
        label: 'This Week',
        path: '/chats',
        params: {
          duration: 'thisWeek',
        },
      },
      {
        label: 'Last  Week',
        path: '/chats',
        params: {
          duration: 'lastWeek',
        },
      },
      {
        label: 'This Month',
        path: '/chats',
        params: {
          duration: 'thisMonth',
        },
      },
      {
        label: 'All',
        path: '/chats/',
        params: {
          duration: 'all',
        },
      },
    ],
  },
];
export const SUPEROWNER_SIDENAV: SideNav[] = [
  {
    label: 'Super Dashboard',
    path: `/SuperDashboard`,
    icon: {
      fontIcon: 'fa-chart-simple',
      fontSet: 'fa-solid',
      className: 'icon',
    },
    allowedRole: ['SuperOwner'],
    id: 'superDashboard',
  },
  {
    label: 'organization',
    id: 'dashboard',
    allowedRole: ['SuperOwner'],
    path: `/organizations`,
    icon: {
      fontIcon: 'fa-building',
      fontSet: 'fa-regular',
      className: 'icon',
    },
  },
];
