export function objectToFormData(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>,
  formData = new FormData(),
  parentKey = ''
) {
  for (const key in obj) {
    const fullKey = parentKey ? `${parentKey}[${key}]` : key;

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          const arrayKey = `${fullKey}[${i}]`;
          if (typeof obj[key][i] === 'object' && obj[key][i] !== null) {
            if (obj[key][i] instanceof File) {
              // Handle binary file
              formData.append(arrayKey, obj[key][i]);
            } else {
              objectToFormData(obj[key][i], formData, arrayKey);
            }
          } else {
            formData.append(arrayKey, obj[key][i]);
          }
        }
      } else {
        if (obj[key] instanceof File) {
          // Handle binary file
          formData.append(fullKey, obj[key]);
        } else {
          objectToFormData(obj[key], formData, fullKey);
        }
      }
    } else {
      formData.append(fullKey, obj[key]);
    }
  }

  return formData;
}
