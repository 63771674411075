import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Auth } from './auth.state';

export const AUTH_STATE_NAME = 'auth';
const selectAuthState = createFeatureSelector<Auth>(AUTH_STATE_NAME);
export const selectToken = createSelector(
  selectAuthState,
  state => state?.token
);
export const selectSetting = createSelector(selectAuthState, state => state);
export const selectOrganization = createSelector(
  selectAuthState,
  state => state.organization
);

export const selectRemainingAttempts = createSelector(
  selectAuthState,
  state => state?.remainingAttempts
);
