import { Role } from '@models';
export const REDIRECT: Record<Role, string> = {
  admin: '/dashboard',
  superowner: '/organizations',
  marketing: '/leads',
  support: '/feedbacks',
  manager: '/leads',
  bot: '',
  training: '/queries',
  service: '/leads',
  supervisor: '/dashboard',
  livechatonly: '/leads',
} as const;
