import { Injectable } from '@angular/core';
import { Encrypt } from './Encrypt';
@Injectable({ providedIn: 'root' })
class LocalStorage {
  private encrypt = new Encrypt();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get<T = string>(key: string, defaultValue: any = undefined) {
    const value = localStorage.getItem(key);
    return (value ? this.encrypt.decode(value) : defaultValue) as T;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public set(key: string, value: any) {
    localStorage.setItem(key, this.encrypt.encode(value));
  }
  public del(key: string) {
    localStorage.removeItem(key);
  }
}
export default LocalStorage;
