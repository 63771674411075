import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  OnDestroy,
  inject,
  OnInit,
  Component,
  ChangeDetectorRef,
  Injectable,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { AppState } from '@stores/app.state';
import { Auth } from '@stores/auth/auth.state';
import { selectSetting } from '@stores/auth/auth.selector';
import { ToastrService } from 'ngx-toastr';
import { BreadCrumb, DynamicForm, Source } from '@models';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RoleUtils } from './RoleUtils';
import { pick } from 'lodash';
import { FileUtils } from './FileUtil';
import { paginationConfig } from '@constants';
import ROLE from '@constants/role';
import dayjs from 'dayjs';

@Injectable({ providedIn: 'root' })
export class BaseComponent implements OnDestroy {
  protected readonly RoleUtils = RoleUtils;
  protected store!: Store<AppState>;
  protected subscriptions$!: Subscription;
  protected auth: Auth;
  protected toastrService: ToastrService;
  protected changeDetectorRef: ChangeDetectorRef;
  protected isInitialized = false;
  protected fileUtil: FileUtils;
  protected dayjs = dayjs;
  protected renderer2 = inject(Renderer2);
  constructor() {
    this.store = inject(Store);
    this.toastrService = inject(ToastrService);
    this.changeDetectorRef = inject(ChangeDetectorRef);
    this.subscriptions$ = new Subscription();
    this.fileUtil = inject(FileUtils);
    this.subscriptions$.add(
      this.store.select(selectSetting).subscribe(setting => {
        this.auth = setting;
        this.onAuthChange();
        this.isInitialized && this.changeDetectorRef.detectChanges();
      })
    );
  }
  handleImageLoadError(event: ErrorEvent, url?: string) {
    this.renderer2.setAttribute(
      event.target,
      'src',
      url ?? 'assets/images/notFound.png'
    );
  }
  ngOnDestroy(): void {
    this.removeSubscription();
  }
  checkRole(role: (keyof typeof ROLE)[] | keyof typeof ROLE): boolean {
    if (!this.auth?.user?.role?.name) return false;
    return this.RoleUtils.checkRole(role, this.auth.user.role.name);
  }
  private removeSubscription() {
    this.subscriptions$?.unsubscribe();
  }
  onInit() {
    this.isInitialized = true;
  }

  isAllowedSource(value: Source['value']): boolean {
    return !!this.auth.setting.source.find(source => source.value === value)
      ?.enable;
  }
  protected getAllowedSource<T extends keyof Source>(
    key: T[] = ['name', 'value'] as T[]
  ): Pick<Source, T>[] {
    return this.auth.setting.source.reduce(
      (acc, curr) => {
        if (curr.enable) {
          acc.push(pick(curr, key));
        }
        return acc;
      },
      [] as Pick<Source, T>[]
    );
  }

  protected onAuthChange() {}
}

@Component({ template: '' })
export abstract class BasePageComponent
  extends BaseComponent
  implements OnInit
{
  breadcrumbs: BreadCrumb[];
  paginationConfig = paginationConfig;
  allowedSource: {
    label: Source['name'];
    value: Source['value'];
  }[];
  abstract initBreadCrumb(): void;
  ngOnInit(): void {
    super.onInit();
    this.initBreadCrumb();
    this.initialize();
  }
  protected initialize() {}

  protected setDropDownSource() {
    this.allowedSource = this.auth.setting.source.reduce(
      (acc, curr) => {
        if (curr.enable) {
          acc.push({
            value: curr.value,
            label: curr.name,
          });
        }
        return acc;
      },
      [] as typeof this.allowedSource
    );
  }
}

@Component({ template: '' })
export abstract class BaseFormComponent<T = unknown>
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  protected isLoading$: Observable<boolean>;
  protected formData: T | undefined;
  protected fb = inject(FormBuilder);
  protected defaultValue: Record<string, unknown>;
  protected formControls: DynamicForm[];
  protected form: FormGroup;

  ngOnInit(): void {
    this.initialize();
    this.buildForm();
  }
  ngAfterViewInit(): void {
    this.patchForm();
  }
  protected initialize() {}
  abstract buildForm(): void;
  abstract patchForm(): void;
  abstract handleSubmit(): void;
  getControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  getFormControlError(name: string) {
    const control = this.getControl(name);
    return !!(control.touched && control.errors);
  }

  getDefaultValue() {
    this.defaultValue = pick(
      this.formData,
      this.formControls.map(({ controlName }) => controlName)
    );
  }
  markFormAsTouched(formGroup?: FormGroup) {
    Object.values(formGroup ? formGroup.controls : this.form.controls)?.forEach(
      control => {
        if (control instanceof FormGroup) {
          this.markFormAsTouched(control);
        } else {
          control.markAsTouched();
        }
      }
    );
  }
}
