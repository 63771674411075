import * as crypto from 'crypto-js';
import { environment } from '@environments';
import { isJson } from './json';
export class Encrypt {
  private secret: string;
  constructor(secret?: string) {
    this.secret = secret ?? environment.secret;
  }
  public encode(data: unknown) {
    if (typeof data === 'object') {
      data = JSON.stringify(data);
    } else {
      data = data?.toString();
    }
    return crypto.AES.encrypt(<string>data, this.secret).toString();
  }
  public decode(data: string): unknown {
    let decoded = crypto.AES.decrypt(data, this.secret).toString(
      crypto.enc.Utf8
    );
    if (isJson(decoded)) decoded = JSON.parse(decoded);
    return decoded;
  }
}
