import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';

export class BaseService {
  protected readonly baseName: string;
  protected httpClient: HttpClient;
  constructor() {
    this.httpClient = inject(HttpClient);
  }

  protected convertToHttpParams(data?: Record<string, unknown>): HttpParams {
    let params = new HttpParams();

    function addToParams(data: unknown, prefix: string) {
      if (typeof data === 'undefined') return;
      if (typeof data === 'object' && data !== null) {
        const dataObj = data as { [key: string]: unknown };

        for (const key in dataObj) {
          const value = dataObj[key];
          const paramKey = prefix ? `${prefix}[${key}]` : key;

          if (Array.isArray(value)) {
            value.forEach((v, index) => {
              addToParams(v, `${paramKey}[${index}]`);
            });
          } else if (typeof value === 'object' && value !== null) {
            addToParams(value, paramKey);
          } else {
            params = params.set(paramKey, value as string);
          }
        }
      } else {
        params = params.set(prefix, data as string);
      }
    }

    if (data)
      for (const [key, value] of Object.entries(data)) {
        addToParams(value, key);
      }

    return params;
  }
  saveFile(response: HttpResponse<Blob>, name?: string): void {
    const contentDispositionHeader = response.headers.get(
      'Content-Disposition'
    );

    const fileNameMatch = contentDispositionHeader?.match(/filename="(.+)"/);

    const fileName =
      name ?? (fileNameMatch ? fileNameMatch[1] : 'downloaded-file');

    // Create a Blob from the response body
    const blob = new Blob([response.body!], {
      type: response.body?.type ?? 'octet/stream',
    });

    // Create a link element and trigger a download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Append the link to the body and click it
    // document.body.appendChild(link);
    link.click();

    // Clean up
    // document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }
}
