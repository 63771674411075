import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileUtils {
  private httpClient = inject(HttpClient);
  convertToBuffer(file: Blob | FileList) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result as ArrayBuffer);
      };

      reader.onerror = reject;

      if (file instanceof Blob) {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsArrayBuffer(file[0]);
      }
    });
  }
  getPreviewUrl(file: Blob | File) {
    return URL.createObjectURL(file);
  }

  async uploadFileToServer(
    file: Blob | File,
    id: string,
    name: string,
    isStatic: boolean = true
  ) {
    const buffer = await this.convertToBuffer(file);

    const params = new HttpParams()
      .append('visitorId', id)
      .append('fileName', name)
      .append('isStatic', isStatic.toString());

    const response = await lastValueFrom(
      this.httpClient.post<{
        message: string;
        path: string;
      }>('uploads/buffer', buffer, {
        headers: new HttpHeaders({
          'Content-Type': 'application/octet-stream',
        }),
        params,
      })
    );

    return response;
  }

  getStaticFileUrl(filePath: string) {
    return `${environment.apiBaseUrl}${environment.port}/${filePath}`;
  }
}
