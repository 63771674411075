export const Category = {
  Holiday: 'holidayEvent',
  Offer: 'offer',
  Organization: 'organizationEvent',
} as const;
export function checkCategory(
  name: keyof typeof Category | (keyof typeof Category)[],
  value: string
): boolean {
  return Array.isArray(name)
    ? name.some(v => Category[v] === value)
    : Category[name] === value;
}
