const ROLE = {
  Admin: 'admin',
  SuperOwner: 'superowner',
  Marketing: 'marketing',
  Support: 'support',
  Manager: 'manager',
  Bot: 'bot',
  Training: 'training',
  Service: 'service',
  Supervisor: 'supervisor',
  Livechatonly: 'livechatonly',
} as const;
export default ROLE;
