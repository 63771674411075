import ROLE from '@constants/role';

export class RoleUtils {
  /**
   * The function checks if a given value matches any of the roles specified in an array or a single role.
   * @param {(keyof typeof ROLE)[] | keyof typeof ROLE} role - The `role` parameter can be either an
   * array of keys from the `ROLE` object or a single key from the `ROLE` object.
   * @param {string} value - The `value` parameter is a string that represents the role value that we want to check.
   * @returns a boolean value.
   */
  static checkRole(
    role: (keyof typeof ROLE)[] | keyof typeof ROLE,
    value: string
  ): boolean {
    if (Array.isArray(role)) {
      return role.some(v => ROLE[v] === value);
    }
    return ROLE[role] === value;
  }
}
