export const sourceColor: Record<string, string> = {
  fb: '#0866ff',
  whatsapp: '#2ab13f',
  instagram: 'yellow',
  slack: 'gray',
  viber: '#793baa',
  web: '#ef4444',
  telegram: 'skyblue',
  chatbot: '#112a5c',
};
