import { inject } from '@angular/core';
import { DateRangePickerModel } from '@models';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';

export class BaseEffect {
  protected action$ = inject(Actions);
  protected store = inject(Store);
  protected toastrService = inject(ToastrService);

  protected getDateFilter(dateRange: DateRangePickerModel.DateFilter) {
    let filter: Record<string, unknown> = {};
    if (dateRange.startDate) {
      filter = {
        ...filter,
        gte: new Date(dateRange.startDate).toISOString(),
      };
    }
    if (dateRange.endDate) {
      filter = {
        ...filter,
        lte: dayjs(dateRange.endDate).endOf('D').toISOString(),
      };
      if (filter['gte']) {
        filter = {
          between: [filter['gte'], filter['lte']],
        };
      }
    }
    return filter;
  }
}
